import './App.css';
import * as React from 'react';
import {
  Routes,
  Route,
  Link,
  Outlet,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Layout, NoMatch } from './components/RouterLayout';
import HackerStories from './components/HackerStories';
import ZhouAudio from './components/ZhouAudio';
import Portfolio from './components/Portfolio';

const ROUTES_LINKS = [
  {
    path: '/zhou',
    name: 'Zhou-Zi Audio',
  },
  {
    path: '/hnews',
    name: 'Hacker News',
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
  },
  {
    path: '/users',
    name: 'Users',
  }
]

const initialUsers = [
  { id: '1', fullName: 'Stephen Zhang' },
  { id: '2', fullName: 'Anita Fu' },
];

const Users = ({ users }) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('name') || '';

  const handleSearch = (event) => { 
    const name = event.target.value;

    if(name) {
      setSearchParams({
        name: event.target.value
      });
    } else {
      setSearchParams({});
    }
   };

  return (
    <>
      <h2>Users</h2>

      <input 
        type="text"
        value={searchTerm}
        onChange={handleSearch}
      />

      <ul>
        {users
          .filter((user) => 
            user.fullName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )
          .map((user) => (
            <li key={user.id}>
              <Link to={user.id}>
                {user.fullName}
              </Link>
            </li>
          ))}
      </ul>

      <Outlet />
    </>
  );
};

const User = ({onRemoveUser}) => {
  const { userId } = useParams();

  return (
    <>
      <h2>User: {userId}</h2>

      <Link to="/users">Back to Users</Link>

      <button type='button' onClick={() => onRemoveUser(userId)}>
        Remove
      </button>
    </>
  );
};

const App = () => {

  const navigate = useNavigate();

  const [users, setUsers] = React.useState(initialUsers);

  const handleRemoveUser = (userId) => {
    setUsers((state) => state.filter((user) => user.id !== userId));
    navigate('/users');
  };

  console.log('B: App');

  return (
    <div role="application">
      <h1> Welcome back to the secrets</h1>

      <Routes>
        <Route element={<Layout links={ROUTES_LINKS}/>}>
          <Route index element={<ZhouAudio />} />
          <Route path="/zhou" element={<ZhouAudio />}></Route>
          <Route path="/hnews" element={<HackerStories />}></Route>
          <Route path="/portfolio" element={<Portfolio />}></Route>
          <Route path="/users" element={<Users users={users} />}>
            <Route
              path=":userId"
              element={<User onRemoveUser={handleRemoveUser} />}
            />
          </Route>
          <Route path="/*" element={<NoMatch />}></Route>
        </Route>
      </Routes>

      {/* Hacker News Component Starts*/}
      {/*
      <HackerStories />
       */}
      {/* Hacker News Component Ends*/}

      {/* Zhou Audio Component Starts*/}
      {/*
          <ZhouAudio />
        */}
      {/* Zhou Audio Component Ends*/}
    </div>
  );
};



export default App;
